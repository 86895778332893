@import '~bootstrap/scss/mixins/breakpoints';
@import 'src/styles/bootstrap-var';
@import 'src/styles/variables';

.showLink {
  display: block;
  img {
    transition: transform .3s;
  }
  &:hover {
    text-decoration: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    img {
      transform: scale(1.05);
      transform-origin: center;
    }
  }
}

.container {
  background-color: var(--background-teaser);
  margin-bottom: $space-m;

  .inner {
    padding: $space-m $space-m + $space-s;
    display: flex;
    flex-flow: column nowrap;
    row-gap: $space-s;

    @include media-breakpoint-up(md) {
      padding: $space-m $space-l;
    }

    @include media-breakpoint-up(lg) {
      padding: $space-m + $space-s $space-l + $space-s;
    }

    h2 {
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
      color: var(--white);
    }

    .description {
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: var(--white);
      text-align: justify;

      p {
        margin: 0;
      }
    }
  }
}
